import React, { useEffect, useState } from "react";
import { login, redeemCode, undoRedeem, checkToken } from "../services/apiServices";
import NusmarkLogoDark from "../media/Nusmark-Logo-Black-Lighter-Smoothed.png";

export default function Redeem() {
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    accessName: "",
    accessPasscode: "",
    transactionAmount: "",
    discountCode: "",
  });
  const [ result, setResult ] = useState(
    {
      status: '',
      valid: false,
      reply: '',
      amount: '',
      firstTimeCustomer: false,
      discountNote: ''
    }
  );

  useEffect(() => {

    setIsLoading(true);
    const token = localStorage.getItem('redeemToken');

    if (token) {
      checkToken({ 
        token: localStorage.getItem('redeemToken') 
      }).then(async (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          setStep(1);
          setIsLoading(false);
        } else {
          setStep(0);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setStep(0);
        setIsLoading(false);
      });
    } else {
      setStep(0);
      setIsLoading(false);
    }
  }, []);


  const handleInputChange = (e) => {
    const { name, value} = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    login(
      { 
        access_name: formData.accessName, 
        access_passcode: formData.accessPasscode 
      }
    )
    .then(async (response) => {
      if (response.status === 200 && response.data.status === 'success') {
        setError('');
        setStep(1);
        setIsLoading(false);
        localStorage.setItem('redeemToken', response.data.token);
      } else {
        setError(response.message || 'Login failed');
        setIsLoading(false);
      }
    })
    .catch((err) => {
      console.error(err);
      setError(err.response.data.message || 'Login failed');
      setIsLoading(false);
    });
  };

  const handleRedeemCode = async (e, updatedCode) => {
    e.preventDefault();
    setIsLoading(true);

    redeemCode (
      {
        token: localStorage.getItem('redeemToken'),
        code: updatedCode,
        transaction_amount: formData.transactionAmount
      }
    ).then(async (response) => {
      if (response.status === 200 && response.data.status === 'success') {
        setError('');
        setStep(2);
        setIsLoading(false);
        setResult(
          {
            status: response.data.status,
            reply: response.data.reply,
            amount: new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(response.data.discounted_transaction_amount),
            firstTimeCustomer: response.data.first_time_customer,
            discountNote: response.data.discount_message 
          }
        );
      } else {
        setError(response.message || 'Redeeming failed');
        setIsLoading(false);
      }
    })
    .catch((err) => {
      console.error(err);
      setError(err.response.data.message || 'Redeeming failed');
      setIsLoading(false);
    });
  };

  const handleLogout = () => {
    const isConfirmed = window.confirm("Are you sure you want to log out?");
    if (!isConfirmed) return;

    setFormData(() => ({
      accessName: "",
      accessPasscode: "",
      transactionAmount: "",
      discountCode: "",
    }));
    setStep(0);
    setError("");
    setResult(
      {
        status: '',
        valid: '',
        reply: '',
        amount: '',
        firstTimeCustomer: '',
        discountNote: ''
      }
    );

    localStorage.removeItem('redeemToken');
  };

  const handleReset = () => {
    setFormData((prevData) => ({
      ...prevData,
      discountCode:"",
      transactionAmount: "",
    }));
    setResult(
      {
        status: '',
        valid: '',
        reply: '',
        amount: '',
        firstTimeCustomer: '',
        discountNote: ''
      }
    );
    setStep(1);
  };

  const handleUndo = () => {
    undoRedeem (
      {
        token: localStorage.getItem('redeemToken'),
        code: formData.discountCode,
      }
    ).then(async (response) => {
      if (response.status === 200 && response.data.status === 'success') {
        setError('');
        setStep(1);
        setIsLoading(false);
        setResult(
          {
            status: '',
            valid: '',
            reply: '',
            amount: '',
            firstTimeCustomer: '',
            discountNote: ''
          }
        );
      } else {
        setError(response.message || 'Undo failed');
        setIsLoading(false);
      }
    })
    .catch((err) => {
      console.error(err);
      setError(err.response.data.message || 'Undo failed');
      setIsLoading(false);
    });
    setStep(1);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-200">
     <div className="flex flex-col items-center w-full pt-10 pl-10 pr-10 mb-10">
        <img
          src={NusmarkLogoDark}
          alt="Nusmark Logo"
          className="h-8 md:h-9 object-contain"
        />
        <p className="ml-2 text-sm leading-none text-slate-400">Ver {process.env.REACT_APP_VERSION}</p>
      </div>
      <div className="w-full max-w-md mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      {isLoading ? (
          <div className="flex flex-col items-center justify-center py-10">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-blue-500"></div>
            <p className="mt-4 text-lg font-semibold">Loading</p>
          </div>

        ) : (
          <>
            {/* Step Indicator */}
            <div className="flex justify-center mb-6">
              {[1, 2].includes(step) && (
                <div className="flex space-x-2">
                  <div
                    className={`w-4 h-4 rounded-full ${
                      step === 1 ? "bg-blue-500" : "bg-gray-300"
                    }`}
                  ></div>
                  <div
                    className={`w-4 h-4 rounded-full ${
                      step === 2 ? "bg-blue-500" : "bg-gray-300"
                    }`}
                  ></div>
                </div>
              )}
            </div>
  
            {/* Step 0: Login */}
            {step === 0 && (
              <div className="flex flex-col justify-center items-center">
                <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
                <form
                  onSubmit={handleLogin}
                  className="w-full max-w-lg p-6 bg-white rounded-lg shadow-lg"
                >
                  <input
                    type="text"
                    name="accessName"
                    value={formData.accessName}
                    onChange={(e) =>
                      handleInputChange({
                        target: {
                          name: e.target.name,
                          value: e.target.value.toUpperCase().trim(),
                        },
                      })
                    }
                    placeholder="Access Name"
                    className="w-full mb-4 p-3 border rounded-lg text-lg"
                  />
                  <input
                    type="password"
                    name="accessPasscode"
                    value={formData.accessPasscode}
                    onChange={handleInputChange}
                    placeholder="Access Code"
                    className="w-full mb-4 p-3 border rounded-lg text-lg"
                  />
                  {error && <p className="text-red-500 text-sm mb-4 text-center">{error}</p>}
                  <button
                    type="submit"
                    className="w-full p-3 bg-blue-500 text-white text-lg font-semibold rounded-lg hover:bg-blue-600"
                  >
                    Sign in
                  </button>
                </form>
              </div>
            )}

            {/* Step 1: Redeem Code */}
            {step === 1 && (
              <div className="flex flex-col justify-center items-center w-full">
                <h2 className="text-2xl font-bold mb-6 text-center">Redeem Code</h2>
                <form
                  onSubmit={(e) => handleRedeemCode(e, formData.discountCode)}
                  className="w-full max-w-lg p-6 bg-white rounded-lg shadow-lg"
                >
<div className="relative mb-4 w-full">
  <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-lg text-gray-500">
    £
  </span>
  <input
    type="tel"
    name="transactionAmount"
    value={(formData.transactionAmount || 0).toFixed(2)}
    onChange={(e) => {
      const value = e.target.value.replace(/[^\d]/g, "");
      const numericValue = parseFloat(value) || 0;

      setFormData((prev) => ({
        ...prev,
        transactionAmount: numericValue / 100,
      }));
    }}
    placeholder="0.00"
    className="w-full pl-8 p-3 border rounded-lg text-lg"
    onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
    onInput={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
    onKeyDown={(e) => {
      if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        e.preventDefault();
      }
    }}
  />
</div>


              <div className="mb-4 w-full">
                <input
                  type="text"
                  name="discountCode"
                  value={formData.discountCode || ""}
                  onChange={(e) => {
                    const updatedCode = e.target.value.toUpperCase().trim();
                    setFormData((prev) => ({
                      ...prev,
                      discountCode: updatedCode,
                    }));
                    if (updatedCode.length >= 4 && formData.transactionAmount >= 4.99) {
                      handleRedeemCode(e, updatedCode);
                    }
                  }}
                  placeholder="Enter discount code"
                  className="w-full p-3 border rounded-lg text-lg"
                />
              </div>

              {error && (
                <p className="text-red-500 text-sm mb-4 text-center">{error}</p>
              )}

              <button
                type="submit"
                className="w-full p-3 bg-blue-500 text-white text-lg font-semibold rounded-lg hover:bg-blue-600"
              >
                Redeem Discount Code
              </button>
            </form>

            <button
              onClick={handleLogout}
              className="w-full mt-6 p-3 bg-red-500 text-white font-semibold rounded-lg text-lg hover:bg-red-600"
            >
              Logout
            </button>
          </div>
          )}

            {/* Step 2: Confirmation */}
            {step === 2 && (
            <>
            {result.firstTimeCustomer && (
              <p className="text-center text-2xl font-bold text-black mb-6">
                You got a new customer 🥳
              </p>
            )}

            <div className="w-full max-w-lg p-8 bg-white text-black rounded-lg shadow-xl">
              <h2 className="text-2xl font-semibold text-center text-blue-600">Valid Code!</h2>

              <p className="mt-6 text-lg text-center text-gray-700">{result.reply}</p>

              <p className="mt-4 text-xl text-center text-blue-500 font-semibold">
                {`Due after discount: ${result.amount}`}
              </p>

              {result.discountNote && (
                <p className="mt-4 text-lg font-medium text-center text-gray-800">
                  {`Discount note: ${result.discountNote}`}
                </p>
              )}

              <div className="mt-6 space-y-4">
                <button
                  id="reset-button"
                  onClick={handleReset}
                  className="w-full p-4 bg-blue-500 text-white font-semibold rounded-lg text-lg hover:bg-blue-600 transition duration-300"
                >
                  Enter another code
                </button>
                
                <button
                  id="undo-button"
                  onClick={handleUndo}
                  className="w-full p-4 bg-gray-500 text-white font-semibold rounded-lg text-lg hover:bg-gray-600 transition duration-300"
                >
                  Undo
                </button>
              </div>
            </div>
            </>
            )} 
          </>
        )}
      </div>
    </div>
  );
}  